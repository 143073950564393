import axios from 'axios';
import { getToken } from '@/common/utils';
import router from '../router/index';
// import { Toast } from "vant";

const DEBUG = process.env.VUE_DEBUG;

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 120 * 1000 // request timeout
});

// axios.defaults.baseURL = "http:/localhost:8383";
// 请求拦截器
service.interceptors.request.use(
  config => {
    // config.baseURL = "http:/localhost:8383"
    // window.console.log('config = ', config);
    window.console.log('config = ', config.baseURL);
    if (config.url.startsWith("http")) {
      config.baseURL = "";
    }
    config.withCredentials = true; // 允许携带token ,这个是解决跨域产生的相关问题
    // config.timeout = 6000;
    config.headers['Content-Type'] = 'application/json';
    // let csrf = store.getters.csrf
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = token;
    }
    // window.console.log('header = ', config.headers);
    // console.log('config data =', config.data);
    return config;
  },
  error => Promise.reject(error)
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    //  // 定时刷新access-token
    //  if (!response.data.value && response.data.data.message === 'token invalid') {
    //   // 刷新token
    //   store.dispatch('refresh').then(response => {
    //    sessionStorage.setItem('access_token', response.data)
    //   }).catch(error => {
    //    throw new Error('token刷新' + error)
    //   })
    //  }
    const res = response.data;
    if (DEBUG) {
      window.console.log('response =', response);
    }
    if (res.code === 401) {
      router.push({ path: '/401' });
    }
    // if (res.code === -1) {
    //   Toast(res.msg);
    // }
    return res;
  },
  error => {
    // router.push({ path: '/500' });
    Promise.reject(error);
  }
);

export default service;
